

$colorBlack: #08010F;
// $colorGreen: #31CB78;
// $colorGreenHover: #57d98f;
// $colorGreenActive: #1fa663;
$colorPurple: #613497;
$colorGrey: #9A96A0;
$colorGrey1: #525252;
$colorGrey2: #A8A8A8;

// $colorMain: #00aeef;            // #00a2d4;
// $colorMainHover: #00aeefad;     // #00a2d4ad
// $colorMainActive: #00aeefad;
// $colorDark: #2E465E;
$colorDanger: #ff4d4f;
$colorDangerV2: #CD4246;
$colorWarning: #faad14;
$colorSuccess: #52c41a;
$colorSuccessV2: #238552;
$colorLpBlue: #1802D0;
$colorLpYellow: #F1C946;

$colorPrimary: $colorLpBlue;

$colorWhite: rgb(255, 255, 255);
$colorWhiteHover: rgba(255, 255, 255, 0.75);
$colorWhiteActive: rgba(255, 255, 255, 0.5);
$colorBgDashboard: #CFD8E3;

$formInputColorDisabled: rgba(0, 0, 0, 0.5);


// $colorFrontEndPrimary: rgb(0, 40, 120);
// $colorFrontEndPrimaryH: rgba(0, 40, 120, 0.75);    // hover
// $colorFrontEndPrimaryA: rgba(0, 40, 120, 0.5);    // active

$colorFrontEndSecondary: #2c519b;     // logic: nhạt hơn 1 tí so với primary

$colorBgSideBar: #001628;
// $colorBgDialog: #333333;
$colorBgTopNav: white;
// $colorBgBody: #222222;
// $colorBgDropDown: #333333;
// $colorBgDropDownItemHover: #616161;
// $colorBgButton: #464646;
// $colorBgButtonDisabled: #46464680;
$colorBodyText: #1a1a1a;
// $colorBodyLabel: #d8d8d8;
// $colorBodyTextHover: #ecececbf;
// $colorBodyTextActive: #ececec7f;
// $colorBodyTextDisabled: #ececec80;
// $colorBodyTextLink: #c8c8ec;
$colorBorder: #d9d9d9;
$colorBorderHover: #3e26de;
// $colorBorderFocus: white;
// $logoTopNav: 'logo_topnav_white.png';
// $colorRowSeparator: #3e3e3e;
// $colorRowHilight:#2e2e2e;



$colorBodyTextLink_Light: #4a4aff;


// public
// $colorBgInput: #0000002f;
$colorBgInputDisabled: #f5f5f5;
// $colorPlaceHolderPublic: #ffffff57;
$pageHeaderH: 70px;
// $pageFooterH: 50px;
$publicContentPaddingBottom: 15px;
// $profileHeaderH: 80px;
// $profileTabHeaderH: 46px;
$colorBgSelection: #bfbfbf;
// $colorUnread: #4a9651;
// $colorOnline: #4a9651;
$colorStatusInProgress: #1890ff;

$colorPick: #226DEE;
$colorDrop: #F1C946;

// admin
$colorBgInputLight: #ffffff;
$colorPlaceHolder: #c0c0c0;
$colorBgBodyAdmin: #ffffff;
$colorBodyTextAdmin: #08010F;
$colorBorderAdmin: #d9d9d9;
$colorInputAdmin: rgba(0, 0, 0, 0.65);
$colorInputHoverAdmin: #2585e6;

$navbar-height: 60px;
$sidebar-width: 250px;
$sidebar-width-sm: 50px;
$inputHeight: 38px;
$commonRadius: 2px;
$formInputTextSize: 16px;
$buttonHeight: 50px;

